@charset 'utf-8';
@import '_env.scss';
@import '_fonts.scss';

html,
body {
	margin: 0;
	padding: 0;
	background: $color_bg;
	color: $color_text;
}

body {
	font-family: 'Noto Sans Japanese', sans-serif;
	font-size: $fontsize_normal;
	line-height: $lineheight_normal;
	letter-spacing: 0.05em;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-print-color-adjust: exact;

	@media (max-width: $breakpoint) {
		font-size: .875rem;
		line-height: 1.71428571429;
	}
}

header,
footer,
main,
section,
nav,
article,
figure,
figcaption,
video,
picture {
	display: block;
	margin: 0;
	padding: 0;
	border: 0 none;
}

input,
textarea,
select,
button {
	font-size: 100%;
	font-family: inherit;
}

a {
	color: $color_link;
	text-decoration: none;

	@media screen and (min-width: $breakpoint) {
		&:hover {
//			text-decoration: underline;
			opacity: .5;
		}
	}
}

p {
	margin: 1.5em 0 0;
	padding: 0;

	small {
		font-size: .875rem;
		color: inherit;
	}
}

table {
	border-collapse: collapse;
	width: 100%;
	font-size: 100%;
}

small {
	font-size: 100%;
	color: #999;
	font-weight: normal;
}
