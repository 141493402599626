@charset 'utf-8';
@import '_env.scss';

.frame {
	padding: 0 20px;
	margin: 0 auto;
	min-width: $maxwidth_content;
	@media (max-width: $breakpoint) {
		min-width: 0;
	}
}

.site-header {
	@media screen and (min-width: $breakpoint) {
		min-height: 110px;
	}

	.site-header-container {
		@extend %clearfix;
		position: relative;
		margin: 0 auto;
		padding: 43px 0 23px;
		width: $maxwidth_content;
		@media (max-width: $breakpoint) {
			width: auto;
		}
		background: $color_bg;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			background: $color_ci;
			border-radius: 0 0 6px 6px;
			height: 6px;
		}
	}

	.title {
		float: left;

		a,
		img {
			float: left;
			display: block;
			margin: 0 auto;
			opacity: 1;
		}

		.corporate {
			height: 44px;
		}

		.site {
			margin-top: 8px;
			margin-left: 20px;
			padding-left: 20px;
			border-left: 1px solid #ccc;
		}
	}

	.navigation {
		.toggle {
			display: none;
		}
	}

	.menu {
		float: right;

		ul {
			float: right;
			margin: 0;
			padding: 0;
			list-style-type: none;
			font-weight: bold;

			> li {
				float: left;
				position: relative;
				margin: 0 0 0 12px;
				padding: 0 0 0 13px;

				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 10px;
					bottom: 10px;
					margin: auto;
					width: 1px;
					background: #d9d9d9;
				}

				&:first-child {
					margin-left: 0;
					padding-left: 0;
					border-left: 0 none;

					&:before {
						content: none;
					}
				}
			}

			a {
				display: inline-block;
				border-radius: 6px;
				padding: 0 14px;
				text-decoration: none;

				@media screen and (min-width: $breakpoint) {
					transition: all 0.16s ease-in-out;

					&:hover {
						background: $color_ci;
						color: $color_bg;
					}
				}

				&.current {
					background: $color_ci;
					color: $color_bg;
				}
			}
		}
	}

	.related-site {
		position: absolute;
		top: 7px;
		right: 0;

		ul {
			float: right;
			margin: 0;
			padding: 0;
			list-style-type: none;
			font-size: $fontsize_exsmall;
			line-height: $lineheight_normal;
			font-weight: bold;

			> li {
				float: left;
				position: relative;
				margin: 0 0 0 14px;
				padding: 0 0 0 15px;

				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 10px;
					bottom: 10px;
					margin: auto;
					width: 1px;
					background: #d9d9d9;
				}

				&:first-child {
					margin-left: 0;
					padding-left: 0;
					border-left: 0 none;

					&:before {
						content: none;
					}
				}
			}

			a {
				&[target="_blank"] {
					&:after {
						content: url(../img/icon_external.png);
						margin: 0 0 0 6px;
						vertical-align: middle;
					}
				}
			}
		}
	}

	@media screen and (min-width: $breakpoint) {
		&.scrolled {
			.site-header-container {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				z-index: 110;
				margin: auto;
				padding: 12px 10px;
				width: auto;
				width: $maxwidth_content;
				@media (max-width: $breakpoint) {
					width: auto;
				}
				height: 36px;
				animation: site-header-scrolled .4s ease-in-out;

				&:before {
					position: fixed;
					top: 0;
					left: 0;
					right: 0;
					z-index: -1;
					border-radius: 0;
					height: 60px;
					background: $color_bg;
					animation: site-header-scrolled .4s ease-in-out;
				}
			}

			.title {
				.corporate {
					height: 36px;
				}

				.site {
					display: none;
				}
			}

			.related-site {
				display: none;
			}

			.menu {
				ul {
					a {
						position: relative;
						background: transparent;
						color: $color_text;

						&:before {
							content: "";
							position: absolute;
							top: -12px;
							left: 0;
							right: 0;
							border-radius: 0 0 6px 6px;
							margin: auto;
							width: 0%;
							height: 6px;
							background: $color_ci;
							transition: width .1s ease-in-out;
						}

						&.current {
							&:before {
								width: 100%;
							}
						}
					}
				}
			}
		}

		@keyframes site-header-scrolled {
			0% {
				top: -78px;
			}
			100% {
				top: 0px;
			}
		}
	}

	@media (max-width: $breakpoint) {
		.site-header-container {
			padding-top: 16px;
			padding-bottom: 14px;

			&:before {
				left: -5px;
				right: -5px;
				border-radius: 0 0 5px 5px;
				height: 5px;
			}
		}

		.title {
			float: none;

			a {
				float: none;
				display: table;
			}

			.corporate {
				height: 28px;
			}

			.site {
				display: none;
			}
		}

		.navigation {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			z-index: 1000;
			margin: 0 0 0 -246px;
			width: 300px;
			overflow: hidden;
			pointer-events: none;

			.toggle {
				display: block;
				padding: 9px;
				pointer-events: auto;

				&:before {
					content: "";
					margin: 0 0 0 auto;
					display: block;
					width: 40px;
					height: 40px;
					background: $color_bg url(../img/icon_menu_closed.png) 50% 50% no-repeat;
				}

				span {
					display: none;
				}
			}

			.menu,
			.related-site {
				opacity: 0;
			}

			&.open,
			&.close {
				transition:
					margin-left 0.2s ease-in-out,
					background 0.2s ease-in-out,
					box-shadow 0.2s ease-in-out
				;

				.menu,
				.related-site {
					transition:
						opacity 0.2s ease-in-out
					;
				}
			}

			&.open {
				margin-left: 0;
				background: $color_bg;
				box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05);
				overflow: auto;
				pointer-events: auto;

				.toggle {
					&:before {
						background-image: url(../img/icon_menu_opened.png);
					}
				}

				.menu,
				.related-site {
					opacity: 1;
				}
			}
		}

		.menu {
			float: none;

			ul {
				float: none;
				border-bottom: 1px solid #ccc;
				font-size: 1rem;

				> li {
					float: none;
					margin: 0;
					padding: 0;
					border-top: 1px solid #ccc;

					&:before {
						content: none;
					}
				}

				a {
					position: relative;
					display: block;
					padding: 15px 25px;
					border-radius: 0;
					background: #fafafa;

					&:after {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						right: 20px;
						margin: auto;
						width: 6px;
						height: 8px;
						background: url(../img/icon_link.png) 0 0 no-repeat;
					}

					&.current {
						&:after {
							content: none;
						}
					}
				}
			}
		}

		.related-site {
			position: static;

			ul {
				float: none;
				margin: 0;
				font-size: 1rem;

				> li {
					float: none;
					margin: 0;
					padding: 0;

					&:before {
						content: none;
					}
				}

				a {
					display: block;
					padding: 15px 25px;
					text-align: left;
				}
			}
		}
	}
}

.site-submenu {
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	max-width: 980px;
	z-index: 100;
	pointer-events: none;

	&.fixed {
		position: fixed;
		top: 60px;
		animation: site-submenu-fixed .4s ease-in-out;
	}
	@keyframes site-submenu-fixed {
		0% {
			top: -110px;
		}
		100% {
			top: 78px;
		}
	}

	.site-submenu-container {
		float: right;
		position: relative;
		margin: 0;
		padding: 0;
		background: $color_bg;
		pointer-events: auto;

		.toggle {
			display: block;
			margin: 0 0 0 auto;
			padding: 0 12px 9px;
			cursor: pointer;
			text-decoration: none;

			span {
				display: none;
			}

			&:before {
				content: "";
				margin: 0 0 0 auto;
				display: block;
				width: 40px;
				height: 40px;
				background: url(../img/icon_menu_closed.png) 50% 50% no-repeat;
			}
		}

		ul {
			display: none;
			position: relative;
			margin: 0 20px 18px;
			padding: 14px 10px 0;
			list-style-type: none;
			font-weight: bold;
			letter-spacing: 0.05em;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 1px;
				background: #ccc;
			}

			> li {
				margin: 4px 0 0;
			}

			a {
				display: block;
			}
		}
	}

	&.open {
		.site-submenu-container {
			.toggle {
				&:before {
					background-image: url(../img/icon_menu_opened.png);
				}
			}

			ul {
				display: block;
			}
		}
	}

	@media (max-width: $breakpoint) {
		display: none;
	}
}

.breadcrumbs,
.contentpath {
	min-width: $maxwidth_content;
	@media (max-width: $breakpoint) {
		min-width: 0;
	}

	.breadcrumbs-container,
	.contentpath-container {
		margin: 0 auto 18px;
		width: $maxwidth_content;
		@media (max-width: $breakpoint) {
			width: auto;
		}
	}

	ol {
		margin: 0;
		padding: 0;
		list-style-type: none;
		font-size: 0.8125rem;
		line-height: 1.46153846154em;
		letter-spacing: 0.1em;
		color: $color_text_weak;

		@media (max-width: $breakpoint) {
			font-size: $fontsize_exsmall;
			line-height: 1em;
		}

		> li {
			display: inline-block;

			&:after {
				content: ">";
				margin: 0 0.5em;
				vertical-align: 1px;
			}

			&:last-child {
				color: $color_link;

				&:after {
					content: none;
				}
			}
		}

		a {
			color: inherit;
		}
	}
}

.breadcrumbs {
	@media (max-width: $breakpoint) {
		display: none;
	}
}

.contentpath {
	margin: 120px -20px 0;
	padding: 0 20px;
	background: #757575;

	.contentpath-container {
		margin-bottom: 0;
		padding: 26px 0;
		border-bottom: 1px solid #ccc;
	}

	ol {
		color: #fff;

		> li {
			&:last-child {
				color: inherit;
			}
		}
	}

	@media (max-width: $breakpoint) {
		padding: 0;

		.contentpath-container {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

.banner-inquiry + .contentpath {
	margin-top: 50px;
}

.site-body {
}

.site-footer {
	margin: 120px -20px 0;
	background: #757575;
	color: #fff;
	min-width: $maxwidth_content + 20px;
	@media (max-width: $breakpoint) {
		min-width: 0;
	}

	a {
		color: #fff;
	}

	@media (max-width: $breakpoint) {
		padding: 0 5px;
	}

	.site-footer-container {
		@extend %clearfix;
		position: relative;
		margin: 0 auto;
		padding: 57px 10px 37px;
		width: $maxwidth_content;
		@media (max-width: $breakpoint) {
			width: auto;
		}

		&:before {
			content: "";
			position: absolute;
			left: 10px;
			right: 10px;
			bottom: 0;
			background: $color_ci;
			border-radius: 6px 6px 0 0;
			height: 7px;
		}

		@media (max-width: $breakpoint) {
			padding-top: 0;

			&:before {
				left: 0;
				right: 0;
				border-radius: 5px 5px 0 0;
				height: 5px;
			}
		}
	}

	.button {
		&:after {
			display: none;
		}
		&:hover {
			.text {
				opacity: .5;
			}
		}
	}

	.menu {
		letter-spacing: 0.05em;

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}

		> ul {
			@extend %clearfix;

			> li {
				float: left;
				width: 25%;

				> ul {
					margin: 25px 0 0;
					padding: 20px 0 0;
					border-top: 1px dotted #999;

					&:first-child {
						margin-top: 0;
						padding-top: 0;
						border-top: 0 none;
					}

					> li {
						font-size: $fontsize_small;
						line-height: 1.42857142857em;

						> a {
							font-weight: 700;
						}

						> ul {
							margin: 24px 0 0;

							> li {
								margin: 6px 0 0;
								font-size: $fontsize_exsmall;
								line-height: 1.5em;
							}
						}
					}
				}
			}
		}

		@media (max-width: $breakpoint) {
			margin-left: -15px;
			margin-right: -15px;

			> ul {
				> li {
					float: none;
					width: auto;

					> ul {
						border-top: 0 none;

						> li {
							border-bottom: 1px solid #ccc;

							> a {
								position: relative;
								display: block;
								padding: 12px 20px 12px 15px;
								font-weight: normal;

								&:before {
									content: "";
									position: absolute;
									top: 0;
									bottom: 0;
									right: 15px;
									margin: auto;
									width: 5px;
									height: 8px;
									background: url(../img/icon_link_white.png) 50% 50% no-repeat;
								}
							}

							> ul {
								display: none;
							}
						}

						&.buttons {
							margin-top: 30px;
							margin-left: -10px;
							padding-top: 0;
							padding-left: 15px;
							padding-right: 15px;

							> li {
								float: left;
								box-sizing: border-box;
								border-bottom: 0 none;
								margin: 0;
								padding: 0 0 0 10px;
								width: 50%;

								> a {
									font-weight: 700;
								}
							}
						}
					}
				}
			}
		}
	}

	.buttons {
		@extend %clearfix;
		margin: 0;
		padding: 0;
		list-style-type: none;
		font-size: $fontsize_small;
		line-height: 1.42857142857em;
		font-weight: 700;

		> li {
			margin-top: 10px;
		}

		.button {
			position: relative;
			display: block;
			padding: 12px 20px;
			border-radius: 6px;
			background: $color_bg;
			color: $color_text;

			&:hover {
				.text {
					opacity: .5;
				}
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 15px;
				margin: auto;
				width: 5px;
				height: 8px;
				background: url(../img/icon_link.png) 50% 50% no-repeat;
			}

			&:after {
				display: none;
			}
		}
	}

	.related-sites {
		@extend %clearfix;
		margin: 60px 0 0;
		padding: 60px 0 55px;
		border-top: 1px dotted #999;

		.buttons {
			float: left;
			width: 25%;

			> li {
				float: left;
				margin-top: 0;
				width: 100%;
			}

			.button {
				background: #999;
				color: #fff;

				&[target="_blank"] {
					&:before {
						right: 13px;
						width: 13px;
						height: 13px;
						background-image: url(../img/icon_external_white.png);
					}
				}
			}
		}

		.sns {
			float: right;
			margin: 0;
			padding: 0;
			list-style-type: none;

			> li {
				float: left;
				margin: 0 0 0 12px;

				&:first-child {
					margin-left: 0;
				}
			}

			a,
			img {
				display: block;
			}
		}

		@media (max-width: $breakpoint) {
			margin-top: 30px;
			padding-top: 30px;
			padding-bottom: 30px;

			.buttons {
				float: none;
				width: auto;
			}

			.sns {
				float: none;
				margin: 30px 0 0;
				width: auto;
				text-align: center;

				> li {
					float: none;
					display: inline-block;
					vertical-align: top;
				}
			}
		}
	}

	.utility {
		float: left;
		font-size: $fontsize_exsmall;
		line-height: 1.66666666667em;

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;

			> li {
				float: left;
				position: relative;
				margin: 0 0 0 20px;
				padding: 0 0 0 21px;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					margin: auto;
					height: 1em;
					width: 1px;
					background: #d9d9d9;
				}

				&:first-child {
					margin-left: 0;
					padding-left: 0;

					&:before {
						content: none;
					}
				}
			}
		}

		@media (max-width: $breakpoint) {
			float: none;
			text-align: center;

			ul {
				> li {
					float: none;
					display: inline-block;
					margin: 0 0 0 15px;
					padding: 0 0 0 16px;

					&:before {
						background: #999;
					}

					&:first-child {
						margin-left: 0;
						padding-left: 0;
					}
				}
			}
		}
	}

	.copyright {
		float: right;
		margin: 0;
		font-size: .625rem;
		line-height: 1.2;
		text-align: right;

		small {
			font-size: 100%;
			color: inherit;
		}

		@media (max-width: $breakpoint) {
			float: none;
			margin: 25px 0 0;
			text-align: center;
		}
	}
}

.banner-inquiry + .site-footer {
	margin-top: 50px;
}

.contentpath + .site-footer {
	margin-top: 0;
}


.page {
	> .header {
		position: relative;
		box-sizing: border-box;
		margin: 0 -20px;

		.title-plain {
			box-sizing: border-box;
			margin: 0 auto;
			padding: 32px 60px 0;
			width: $maxwidth_content;
			@media (max-width: $breakpoint) {
				width: auto;
			}
			text-align: center;

			@media (max-width: $breakpoint) {
				padding-left: 15px;
				padding-right: 15px;
				padding-bottom: 0;
			}

			.category {
				margin: 0;
				padding: 0;
				color: #808080;
				line-height: 1.5;

				&:before,
				&:after {
					content: "-";
					margin: 0 0.25em;
				}
			}

			.title {
				margin: 5px 0;
				padding: 0;
				font-weight: 700;
				font-size: 2.25rem;
				line-height: 1.5;
				letter-spacing: 0.05em;
			}

			.pubdate {
				margin: 18px 0 0;
				padding: 0;
				text-align: right;
				font-weight: 700;
				letter-spacing: 0.025em;
			}
		}

		.title-cover {
			position: relative;
			display: block;
			margin: 0;
			padding: 0;
			width: 100%;
			color: #fff;

			figcaption {
				position: absolute;
				left: 15px;
				right: 15px;
				top: 0;
				bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				@media (max-width: $breakpoint) {
					bottom: 6px;
				}
			}

			.cover-image {
				display: block;
				width: 100%;
			}

			.title {
				margin: 0;
				padding: 0;
				text-align: center;
				font-size: $fontsize_exlarge;
				line-height: 1.33333333333;
				letter-spacing: 0.05em;

				@media (max-width: $breakpoint) {
					font-size: 1.375rem;
				}
			}

			.subtitle {
				margin: 22px 0 0;
				padding: 0;
				text-align: center;
				font-size: 0.9375rem;
				line-height: 1.26666666667em;

				@media (max-width: $breakpoint) {
					margin-top: 8px;
					font-size: .5rem;
				}
			}

			.lead {
				text-align: center;

				position: relative;
				box-sizing: border-box;
				margin: 24px auto 0;
				padding: 0 30px;
				width: $maxwidth_content;
				@media (max-width: $breakpoint) {
					width: auto;
				}
				font-size: 1.5rem;
				line-height: 1.66666666667;
				letter-spacing: 0.05em;
				text-align: center;

				@media (max-width: $breakpoint) {
					padding: 0 20px;
					font-size: 1rem;
					line-height: 1.5;
				}

				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					border-left: 1px solid;
					border-top: 1px solid;
					width: 12px;
					height: 24px;

					@media (max-width: $breakpoint) {
						height: 18px;
					}
				}

				&:after {
					content: "";
					position: absolute;
					right: 0;
					bottom: 0;
					border-right: 1px solid;
					border-bottom: 1px solid;
					width: 12px;
					height: 24px;

					@media (max-width: $breakpoint) {
						height: 18px;
					}
				}

				.phrase {
					display: inline-block;
				}
			}
		}

		.title-cover-small {
			position: relative;
			display: block;
			margin: 0;
			padding: 0;
			width: 100%;

			figcaption {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				@media (max-width: $breakpoint) {
					bottom: 6px;
				}
			}

			.cover-image {
				display: block;
				width: 100%;
			}

			.category {
				margin: 0 0 12px;
				padding: 0 15px;
				text-align: center;
				font-weight: 700;
				font-size: 0.9375rem;
				line-height: 1.26666666667em;
				color: #808080;
				color: #fff;

				&:before {
					content: "- ";
				}

				&:after {
					content: " -";
				}

				@media (max-width: $breakpoint) {
					margin-bottom: 8px;
					font-size: .5rem;
				}
			}

			.title {
				margin: 0 0 8px;
				padding: 0 15px;
				text-align: center;
				font-size: $fontsize_exlarge;
				line-height: 1.33333333333;
				letter-spacing: 0.05em;
				color: #fff;

				@media (max-width: $breakpoint) {
					font-size: 1.375rem;
				}
			}
		}
	}


	> .body {
		position: relative;
		box-sizing: border-box;
		margin: 0 auto;
		padding: 50px 60px 0;
		width: $maxwidth_content;
		@media (max-width: $breakpoint) {
			width: auto;
		}
		min-height: 100px;
		background: $color_bg;

		@media (max-width: $breakpoint) {
			margin-top: 0;
			margin-left: -20px;
			margin-right: -20px;
			padding-top: 30px;
			padding-left: 15px;
			padding-right: 15px;
		}

		> :first-child {
			margin-top :0;

			> li:first-child {
				margin-top :0;
			}
		}
	}

	> .footer {
	}
}



.banner-inquiry {
	position: relative;
	margin: 130px auto -10px;
	height: 90px;
	transition: opacity .4s ease-in-out;

	@media (max-width: $breakpoint) {
		margin-left: -20px;
		margin-right: -20px;
		height: 63px;
	}

	.phrase {
		display: inline-block;
	}

	&.fixed {
		dl {
			position: fixed;
			z-index: 99;
		}

		.close {
			position: absolute;
			cursor: pointer;
			left: 10px;
			top: -25px;
			width: 15px;
			height: 15px;
			background: url(../img/icon_close.png) 0 0 no-repeat;
			background-size: 100%;
		}
	}

	dl {
		position: absolute;
		bottom: 10px;
		left:0;
		right: 0;
		z-index: 100;
		box-sizing: border-box;
		display: flex;
		margin: 0 auto;
		padding: 20px 25px 20px 30px;
		width: ($maxwidth_content - 40px);
		border-radius: 6px;
		justify-content: space-between;
		align-items: center;
		background: #666;
		color: #fff;

		@media (max-width: $breakpoint) {
			bottom: 0;
			border-radius: 0;
			padding: 10px;
			width: auto;
		}

		dt,
		dd {
			margin: 0;
			padding: 0;
		}

		.title {
			margin: 0 40px 0 0;
			font-weight: 700;
			font-size: 1.25rem;
			line-height: 1.5;
			letter-spacing: -0.05em;

			@media (max-width: $breakpoint) {
				margin-right: 6px;
				font-size: .75rem;
			}
		}

		.contact {
			margin: 0 40px 0 0;

			@media (max-width: $breakpoint) {
				margin-right: 6px;
			}
		}

		.tel {
			display: block;
			font-weight: 700;
			font-size: 2rem;
			line-height: 1.25em;
			letter-spacing: -0.05em;
			white-space: nowrap;
			@media (max-width: $breakpoint) {
				font-size: 1.125rem;
			}
		}

		.time {
			display: block;
			font-size: .875rem;
			line-height: 1.28571428571em;
			letter-spacing: -0.05em;
			@media (max-width: $breakpoint) {
				letter-spacing: -0.08em;
				font-size: .625rem;
			}
		}

		.or {
			margin: 0 15px 0 0;
			font-size: .875rem;
			line-height: 1.28571428571em;
			letter-spacing: -0.1em;
		}

		.buttons {
			width: 220px;
			@media (max-width: $breakpoint) {
				width: 100px;
			}
		}

		.button {
			width: 100%;
			font-size: .75rem;
			background: $color_bg;
			color: $color_text;
			letter-spacing: -0.05em;
			opacity: 1;

			&:after {
				display: none;
			}

			&:hover {
				span {
					opacity: .5;
				}
			}

			@media (max-width: $breakpoint) {
				padding: 5px 14px 5px 7px;

				.sp {
					display: block;
					margin: -0.3em -2.4em -0.3em 0;
					font-size: .5rem;
					transform: scale(0.7);
					transform-origin: 0 50%;
				}

				.phrase {
					font-size: .625rem;
				}

				&:before {
					right: 7px;
					width: 6px;
					height: 8px;
					background: url(../img/icon_link.png) 0 0 no-repeat;
				}
			}
		}
	}

	&.fixed.closed {
		dl {
			bottom: 0;
			padding: 6px 0;
			border-radius: 6px 6px 0 0;
			width: 220px;
			justify-content: center;
			cursor: pointer;
			@media (max-width: $breakpoint) {
				width: 168px;
			}

			.close {
				display: none;
			}

			.title {
				margin-right: 0;
				font-size: 1rem;
				text-align: center;
				@media (max-width: $breakpoint) {
					font-size: .75rem;
				}

				.pc {
					display: inline;
				}
				br {
					display: none;
				}
				span.sp {
					display: none;
				}
			}
			.contact,
			.or,
			.buttons {
				display: none;
			}
		}
	}
}




