@charset 'utf-8';
@import '../_env.scss';

.page > .body > .index:first-child {
	margin-top: 0;
}

.index {
	display: flex;
	flex-wrap: wrap;
	margin: 59px -60px 0;
	padding: 0;
	list-style-type: none;

	@media (max-width: $breakpoint) {
		margin-left: -5px;
		margin-right: -5px;
	}

	> li {
		box-sizing: border-box;
		margin: 40px 0 0;
		padding: 0 10px;
		width: 50%;

		&:first-child,
		&:first-child + li {
			margin-top: 0;
		}

		&:nth-child(2n - 1) {
			padding-left: 0;
		}

		&:nth-child(2n) {
			padding-right: 0;
		}
	}
}

.index-item {
	display: block;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 23px;
		margin: auto;
		width: 18px;
		height: 18px;
		background: url(../img/icon_index.png) 0 0 no-repeat;

		@media (max-width: $breakpoint) {
			margin: 0;
			top: auto;
			bottom: 10px;
			right: 10px;
		}
	}

	.thumb {
		display: block;
		border-radius: 6px;
		max-width: 100%;
	}

	figure {
		display: block;
		margin: 0;
		padding: 0;
		width: auto;
	}

	figcaption {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		padding: 40px 40px 50px 40px;
		flex-direction: column;
		justify-content: center;
		@media (max-width: $breakpoint) {
			padding: 15px;
		}

		> br {
			display: none;
		}

		.title {
			display: block;
			font-weight: 700;
			font-size: 1.5rem;
			line-height: 1.5;
			letter-spacing: 0.05em;

			@media (max-width: $breakpoint) {
				font-size: 1rem;
			}
		}

		.subtitle {
			display: block;
			margin: 2px 0 0;
			font-size: .75rem;
			line-height: 1.5;

			@media (max-width: $breakpoint) {
				font-size: .5rem;
			}
		}
	}
}
