@charset 'utf-8';
@import '../_env.scss';

.link-list-strong {
	margin: 26px 0 0;
	padding: 0;
	list-style-type: none;
	font-size: 1rem;
	line-height: 1.5;
	letter-spacing: 0.05em;
	font-weight: 500;

	@media (max-width: $breakpoint) {
		font-size: .75rem;
		letter-spacing: 0.025em;
		font-weight: 700;
	}

	> li {
		margin: 16px 0 0;
		padding: 0 0 0 23px;
		text-indent: -23px;

		&:first-child {
			margin-top: 0;
		}
	}

	a {
		&:before {
			content: "";
			display: inline-block;
			margin: 0 5px 0 0;
			width: 18px;
			height: 18px;
			background: url(../img/icon_index.png) 0 0 no-repeat;
			vertical-align: -3px;
			@media (max-width: $breakpoint) {
				vertical-align: -4px;
			}
		}

		&[target]:after {
			content: "";
			display: inline-block;
			margin: 0 0 0 10px;
			width: 13px;
			height: 13px;
			background: url(../img/icon_external.png) 0 0 no-repeat;
			vertical-align: -1px;
			@media (max-width: $breakpoint) {
				margin-left: 5px;
				vertical-align: -2px;
			}
		}
	}
}

.link-list {
	margin: 40px 0 0;
	padding: 0;
	list-style-type: none;
	font-size: .875rem;
	line-height: 1.5;
	letter-spacing: 0.025em;

	@media (max-width: $breakpoint) {
		font-size: .75rem;
	}

	> li {
		margin: 20px 0 0;
		padding: 0 0 0 16px;
		text-indent: -16px;

		@media (max-width: $breakpoint) {
			padding-left: 14px;
			text-indent: -14px;
		}
	}

	a {
		&:before {
			content: "";
			display: inline-block;
			margin: 0 10px 0 0;
			width: 6px;
			height: 8px;
			background: url(../img/icon_link.png) 0 0 no-repeat;
			vertical-align: 2px;

			@media (max-width: $breakpoint) {
				margin-right: 8px;
				vertical-align: 1px;
			}
		}

		&[target]:after {
			content: "";
			display: inline-block;
			margin: 0 0 0 10px;
			width: 13px;
			height: 13px;
			background: url(../img/icon_external.png) 0 0 no-repeat;
			vertical-align: -1px;

			@media (max-width: $breakpoint) {
				margin-left: 5px;
				vertical-align: -2px;
			}
		}
	}
}


.link-tab-list {
	display: flex;
	margin: 40px 0 0;
	padding: 0;
	list-style-type: none;
	justify-content: flex-end;
	font-weight: 700;
	letter-spacing: 0.025em;

	@media (max-width: $breakpoint) {
		justify-content: center;
	}

	> li {
		margin: 0 0 0 50px;

		@media (max-width: $breakpoint) {
			margin-left: 30px;
		}

		&:first-child {
			margin-left: 0;
		}
	}

	a {
		position: relative;
		padding: 0 0 10px;
		color: $color_text_weak;

		&.current {
			color: $color_text;
			text-decoration: none;

			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				border-radius: 2px;
				height: 4px;
				background: $color_ci;
			}
		}
	}
}


.link-page-list {
	display: flex;
	margin: 40px 0 0;
	padding: 0;
	list-style-type: none;
	justify-content: flex-end;
	font-weight: 700;
	line-height: 2.25em;

	@media (max-width: $breakpoint) {
		line-height: 2em;
		font-size: .75rem;
		justify-content: center;
	}

	> li {
		position: relative;
		margin: 0 0 0 10px;
		padding: 0 0 0 10px;

		@media (max-width: $breakpoint) {
			margin-left: 5px;
			padding-left: 5px;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 1px;
			height: 20px;
			background: $color_text;

			@media (max-width: $breakpoint) {
				height: 14px;
			}
		}

		&:first-child {
			margin-left: 0;
			padding-left: 0;

			&:before {
				content: none;
			}
		}
	}

	a {
		display: block;
		border-radius: 6px;
		padding: 0 0.5em;

		@media (max-width: $breakpoint) {
			padding: 0 0.75em;
		}

		&.current {
			background: $color_ci;
			color: $color_bg;
		}
	}
}



.link-list-internal {
	margin: 40px 0 0;
	padding: 0;
	list-style-type: none;
	font-size: .875rem;
	line-height: 1.5;
	letter-spacing: 0.025em;

	@media (max-width: $breakpoint) {
		font-size: .75rem;
	}

	> li {
		margin: 20px 0 0;
		padding: 0 0 0 16px;
		text-indent: -16px;

		@media (max-width: $breakpoint) {
			padding-left: 14px;
			text-indent: -14px;
		}
	}

	a {
		&:before {
			content: "";
			display: inline-block;
			margin: 0 10px 0 0;
			width: 6px;
			height: 8px;
			background: url(../img/icon_link.png) 0 0 no-repeat;
			vertical-align: 2px;
			transform: rotate(90deg);

			@media (max-width: $breakpoint) {
				margin-right: 8px;
				vertical-align: 1px;
			}
		}
	}
}


