@charset 'utf-8';
@import '../_env.scss';

.block-attention {
	margin: 60px 0 16px;
	color: $color_attention;

	p {
		margin: 0;
		padding: 0;
		font-size: 1.125rem;
		line-height: 1.77777777778;
		letter-spacing: 0.05em;
		text-indent: -0.5em;

		@media (max-width: $breakpoint) {
			font-size: .9375rem;
			line-height: 1.6;
		}

		&:before {
			content: "「";
		}
	}

	.phrase {
		display: inline-block;
		text-indent: 0;

		&:last-child {
			&:after {
				content: "」";
			}
		}
	}
}

.block-attention-large {
	margin: 60px 0 30px;
	color: $color_attention;

	p {
		position: relative;
		display: table;
		margin: 0;
		padding: 0 36px;
		font-size: 1.375rem;
		line-height: 1.63636363636;
		letter-spacing: 0.05em;

		@media (max-width: $breakpoint) {
			padding: 0 20px;
			font-size: 1rem;
			line-height: 1.5;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			border-left: 1px solid;
			border-top: 1px solid;
			width: 12px;
			height: 24px;

			@media (max-width: $breakpoint) {
				height: 18px;
			}
		}

		&:after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			border-right: 1px solid;
			border-bottom: 1px solid;
			width: 12px;
			height: 24px;

			@media (max-width: $breakpoint) {
				height: 18px;
			}
		}
	}

	.phrase {
		display: inline-block;
	}
}

.block-attention-exlarge {
	margin: 60px 0 30px;
	color: $color_attention;

	p {
		position: relative;
		box-sizing: border-box;
		margin: 0 auto;
		padding: 0 36px;
		max-width: 680px;
		font-size: 1.5rem;
		line-height: 1.66666666667;
		letter-spacing: 0.05em;
		text-align: center;

		@media (max-width: $breakpoint) {
			padding: 0 20px;
			font-size: 1.25rem;
			line-height: 1.5;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			border-left: 1px solid;
			border-top: 1px solid;
			width: 12px;
			height: 24px;

			@media (max-width: $breakpoint) {
				height: 18px;
			}
		}

		&:after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			border-right: 1px solid;
			border-bottom: 1px solid;
			width: 12px;
			height: 24px;

			@media (max-width: $breakpoint) {
				height: 18px;
			}
		}
	}

	.phrase {
		display: inline-block;
	}
}



.block-image {
	margin: 50px 0 0;

	img {
		display: block;
		margin: auto;
		max-width: 100%;
	}
}
a:hover .block-image {
	opacity: .7;
}


.block-image-large {
	margin: 40px -60px 0;

	@media (max-width: $breakpoint) {
		margin-left: 0;
		margin-right: 0;
	}

	img {
		display: block;
		margin: auto;
		max-width: 100%;
		border-radius: 6px;
	}
}



.block-columns-ltr,
.block-columns-rtl,
.block-columns-3,
.block-columns-4 {
	display: flex;
	margin: 30px 0 0;

	@media (max-width: $breakpoint) {
		display: block;
	}

	> .col {
		position: relative;

		> p:first-child {
			margin-top: 40px;
		}

		.block-image-h3 {
			margin-top: 110px;
		}

		@media (max-width: $breakpoint) {
			> :first-child {
				margin-top: 10px;
			}

			> section:first-child > .section-header {
				> .h2,
				> .h3,
				> .h4 {
					margin-top: 10px;
				}
			}

			.block-image {
				margin-top: 40px;
			}
		}

		.button-list {
			> li {
				width: 280px;
			}
		}

		.col-footer {
			@extend %clearfix;
			display: flex;
			width: 100%;
			align-items: flex-end;

			@media (max-width: $breakpoint) {
				position: static;
			}

			.link-list,
			.link-list-strong {
				float: left;
				flex: 1;
			}

			.banner {
				float: right;
				margin: 40px 0 -6px;
				flex: 1;

				@media (max-width: $breakpoint) {
					margin-top: 35px;
				}

				img {
					display: block;
					max-width: 100%;
				}
			}
		}
	}
}


.block-columns-ltr {
	> .col {
		&:first-child {
			margin: 0;
			width: 47.560975609%;

			@media (max-width: $breakpoint) {
				margin: 0;
				width: auto;
			}
		}

		&:last-child {
			flex: 1;
			margin: 0 0 0 40px;

			@media (max-width: $breakpoint) {
				margin: 20px 0 0;
			}
		}
	}
}



.block-columns-rtl {
	> .col {
		&:first-child {
			order: 2;
			margin: 0;
			width: 47.560975609%;

			@media (max-width: $breakpoint) {
				margin: 0;
				width: auto;
			}
		}

		&:last-child {
			order: 1;
			flex: 1;
			margin: 0 40px 0 0;

			@media (max-width: $breakpoint) {
				margin: 20px 0 0;
			}
		}
	}
}



.block-bg,
.block-bg-silver,
.block-bg-gray,
.block-bg-none {
	margin: 50px calc(-1 * (100vw - #{$maxwidth_content} + 120px) / 2) 0;
	padding: 30px 0 50px;
	width: 100vw;
	background: #fafafa;
	letter-spacing: -0.05em;

	@media (max-width: $maxwidth_content + 40px) {
		margin-left: -80px;
		margin-right: -80px;
		width: $maxwidth_content + 40px;
	}

	@media (max-width: $breakpoint) {
		margin-left: -15px;
		margin-right: -15px;
		width: auto;
	}

	& + & {
		margin-top: 2px;
	}

	.block-bg-body {
		box-sizing: border-box;
		margin: 0 auto;
		padding: 0 60px;
		max-width: $maxwidth_content;

		@media (max-width: $breakpoint) {
			padding: 0 15px;
		}

		.h4 {
			margin-top: 50px;
			margin-bottom: 14px;
		}

		> :first-child,
		> section:first-child > .section-header > .h3,
		> section:first-child > .section-header > .h4 {
			margin-top: 0;
		}

		> section:first-child > .section-header > .h2 {
			margin-top: 20px;
		}

		> .block-columns-ltr,
		> .block-columns-rtl {
			> .col {
				> :first-child {
					margin-top: 0;
				}

				> section:first-child > header {
					.h2,
					.h3,
					.h4 {
						margin-top: 0;

						@media (max-width: $breakpoint) {
							margin-top: 30px;
						}
					}
				}
			}
		}
	}
}
.block-bg-silver {
	background: #f2f2f2;
}
.block-bg-gray {
	background: #b3b3b3;
	color: #fff;
}
.block-bg-none {
	background: transparent;
}


.block-bg-area {
	margin: 40px 0 0;
	padding: 25px 30px;
	background: #fafafa;

	> :first-child {
		margin-top: 0;

		> dt:first-child,
		> li:first-child {
			margin-top: 0;
		}
	}
}


.block-plate {
	margin: 30px -40px;
	padding: 40px;
	background: $color_bg;
	color: $color_text;
	border-radius: 6px;

	@media (max-width: $breakpoint) {
		margin-left: 0;
		margin-right: 0;
	}

	> :first-child {
		margin-top: 0;
	}
}

.block-plate-2 {
	display: flex;
	margin: 30px -40px;

	@media (max-width: $breakpoint) {
		display: block;
		margin-left: 0;
		margin-right: 0;
	}

	> .plate {
		flex: 1;
		margin: 0 0 0 20px;
		border-radius: 6px;
		background: $color_bg;
		color: $color_text;

		@media (max-width: $breakpoint) {
			margin: 30px 0 0;
		}

		&:first-child {
			margin-left: 0;
		}

		&:empty {
			background: #bababa;

			@media (max-width: $breakpoint) {
				display: none;
			}
		}
	}
}


.block-columns-2 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;

	> .col {
		box-sizing: border-box;
		padding: 0 10px;
		width: 50%;
	}
}



.block-columns-3 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;

	> .col {
		box-sizing: border-box;
		margin: 50px 0 0;
		padding: 0 10px;
		width: 33.3333%;

		&.col-2 {
			width: 66.6666%;
		}

		&:first-child,
		&:first-child + .col,
		&:first-child + .col + .col {
			margin-top: 0;
		}

		@media (max-width: $breakpoint) {
			width: 50%;

			&.col-2 {
				width: 50%;
			}

			&:first-child + .col + .col {
				margin-top: 50px;
			}
		}
	}
}



.block-columns-4 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;

	> .col {
		box-sizing: border-box;
		padding: 0 10px;
		width: 25%;

		&.col-2 {
			width: 50%;
		}

		@media (max-width: $breakpoint) {
			width: 50%;
		}
	}
}

.block-columns-wrap {
	@media (max-width: $breakpoint) {
		display: block;

		> .col,
		> .col.col-2 {
			width: auto;
		}
	}
}



.block-detail {
	display: flex;
	margin: 30px 0 0;
	padding: 0 0 30px;
	border-bottom: 1px solid #ccc;

	.block-detail-image {
		width: 120px;

		@media (max-width: $breakpoint) {
			width: 75px;
		}

		img {
			display: block;
			max-width: 100%;
		}
	}

	.block-detail-body {
		flex: 1;
		margin: 0 0 0 20px;
	}

	.block-detail-title {
		margin: -9px 0 0;
		padding: 0;
		font-size: 1.25rem;
		line-height: 1.8em;

		@media (max-width: $breakpoint) {
			font-size: 1rem;
		}
	}

	.block-detail-data {
		margin: 15px 0 0;
		border-collapse: collapse;
		width: 100%;
		@media (max-width: $breakpoint) {
			font-size: .75rem;
		}

		th,
		td {
			padding: 7px 15px;
			border: 1px solid #fff;
			text-align: left;
			line-height: 1.5em;
			width: 50%;
		}

		th {
			background: #e5e5e5;
		}
		td {
			background: #f2f2f2;
		}
	}
}



.block-checkbox {
	display: flex;
	position: relative;
	margin: 0 -10px;
	padding: 0 0 30px;

	&:before {
		content: "";
		position: absolute;
		left: 10px;
		right: 10px;
		top: -1px;
		border-bottom: 1px solid #ccc;
	}

	&:after {
		content: "";
		position: absolute;
		left: 10px;
		right: 10px;
		bottom: 0;
		border-bottom: 1px solid #ccc;
	}

	@media (max-width: $breakpoint) {
		display: block;
		padding-top: 30px;
	}

	.block-checkbox-header {
		box-sizing: border-box;
		padding: 20px 10px 0;
		width: 33.3333%;

		.checkbox {
			display: block;
			padding-left: 30px;
			text-indent: -30px;
			font-weight: 700;
		}

		@media (max-width: $breakpoint) {
			padding-top: 0;
			width: auto;
		}
	}

	.block-checkbox-body {
		box-sizing: border-box;
		padding: 0 10px;
		width: 66.6666%;

		@media (max-width: $breakpoint) {
			width: auto;
		}
	}

	.block-image {
		margin-top: 30px;
	}
}



.block-checkbox-document {
	display: flex;
	position: relative;
	margin: 0 -10px;
	padding: 0 0 30px;

	&:before {
		content: "";
		position: absolute;
		left: 10px;
		right: 10px;
		top: -1px;
		border-bottom: 1px solid #ccc;
	}

	&:after {
		content: "";
		position: absolute;
		left: 10px;
		right: 10px;
		bottom: 0;
		border-bottom: 1px solid #ccc;
	}

	@media (max-width: $breakpoint) {
		display: block;
		padding-top: 30px;
	}

	.block-checkbox-header {
		box-sizing: border-box;
		padding: 20px 10px 0;
		width: 54px;

		.checkbox {
			display: block;
			padding-left: 30px;
			text-indent: -30px;
			font-weight: 700;
		}

		@media (max-width: $breakpoint) {
			padding-top: 0;
			width: auto;
		}
	}

	.block-checkbox-image {
		margin: 30px 0 0;
		width: 260px;

		img {
			display: block;
			margin: auto;
		}

		@media (max-width: $breakpoint) {
			margin: 20px 0 0;
			width: auto;
		}
	}

	.block-checkbox-body {
		box-sizing: border-box;
		padding: 0 0 0 20px;
		flex: 1;

		@media (max-width: $breakpoint) {
			padding: 0 10px;
		}

		.title {
			margin: 24px 0 0;
			padding: 0;
			font-size: 1.25rem;

			@media (max-width: $breakpoint) {
				font-size: 1rem;
			}

			+ p {
				margin: 10px 0 0;
			}
		}

		@media (max-width: $breakpoint) {
			width: auto;
		}
	}

	.block-image {
		margin-top: 30px;
	}
}



.block-flow {
	@extend %clearfix;
	position: relative;
	margin: 25px 0 0;
	padding: 30px;
	background: #f5f5f5;

	@media (max-width: $breakpoint) {
		margin-top: 28px;
		padding: 0;
	}

	& + &:before {
		content: "";
		position: absolute;
		top: -25px;
		left: 0;
		right: 0;
		margin: auto;
		width: 8px;
		height: 25px;
		background: url(../img/icon_flow.png) 0 0 no-repeat;

		@media (max-width: $breakpoint) {
			top: -28px;
			width: 13px;
			height: 28px;
			background: url(../img/icon_flow_sp.png) 0 0 no-repeat;
			background-size: 13px 28px;
		}
	}

	.block-flow-header {
		@media (max-width: $breakpoint) {
			display: flex;
			padding: 0 0 0 20px;
			align-items: center;
		}
	}

	.block-flow-thumb {
		float: right;
		margin: -30px -30px -30px 0;
		width: 260px;
		@media (max-width: $breakpoint) {
			float: none;
			margin: 0;
			width: 167px;
			order: 2;
		}
	}

	.block-flow-title {
		margin: 0;
		padding: 0;
		font-size: 1.25rem;
		line-height: 1;

		@media (max-width: $breakpoint) {
			flex: 1;
			order: 1;
			font-size: 1rem;
		}
	}

	.block-flow-lead {
		font-size: 1rem;
		line-height: 1.5em;

		@media (max-width: $breakpoint) {
			font-size: .875rem;
			line-height: 1.25em;
		}
	}

	.block-flow-body {
		margin: 0 260px 0 0;
		padding: 0 0 0 30px;

		@media (max-width: $breakpoint) {
			margin: 0;
			padding: 0 20px 20px;
		}

		p {
			margin: 0;
			padding: 0;
			font-size: .875rem;
			line-height: 2;
			letter-spacing: -0.08em;

			@media (max-width: $breakpoint) {
				font-size: .75rem;
				line-height: 1.666667em;
			}
		}
	}
}


.block-merit,
.block-demerit {
	margin: 40px 0 0;

	& + & {
		margin-top: 10px;
		border-top: 1px dotted #999;
	}

	.merit,
	.demerit {
		@extend %clearfix;
		position: relative;
		margin: 10px 0 0;
		padding: 20px;
		background: #f2f2f2;
		border-radius: 6px;
		cursor: pointer;

		&:hover {
			opacity: .8;
			transition: opacity .2s ease-in-out;
		}

		&:before {
			content: "";
			position: absolute;
			right: 32px;
			top: 32px;
			border-top: 8px solid #999;
			border-right: 6px solid transparent;
			border-left: 6px solid transparent;
			transition: transform .1s ease-in-out;
		}

		> dt,
		> dd {
			margin: 0;
			padding: 0;
		}

		> .num,
		> .title {
			font-weight: 700;
			font-size: 1.25rem;
			line-height: 1.5;
		}

		> .num {
			float: left;
			width: 136px;

			@media (max-width: $breakpoint) {
				float: none;
				width: auto;
			}
		}

		> .title {
			margin: 0 40px 0 136px;

			@media (max-width: $breakpoint) {
				margin-left: 0;
			}
		}

		> .description {
			clear: both;
			margin:  0 0 0 136px;
			font-size: .875rem;
			line-height: 2;
			overflow: hidden;
			height: 0;

			@media (max-width: $breakpoint) {
				margin-left: 0;
			}

			.accordion-content {
				padding: 10px 0 0;
			}
		}

		&.open {
			&:before {
				transform: rotate(180deg);
			}

			> .description {
				height: auto;
			}
		}
	}

	.demerit {
		background: #7f7f7f;
		color: #fff;

		&:before {
			border-top-color: #fff;
		}
	}
}


.block-related-info {
	display: block;
	padding: 30px 40px;
	text-decoration: none;

	dl,
	dt,
	dd {
		margin: 0;
		padding: 0;
	}

	.block-related-info-header {
		display: flex;
		align-items: center;

		img {
			display: block;
			width: 100px;
			margin: 0 20px 0 0;
		}

		.title {
			flex: 1;
			display: block;
			font-weight: 700;

			&:before {
				content: "";
				display: inline-block;
				margin: 0 10px 0 0;
				width: 18px;
				height: 18px;
				background: url(../img/icon_index.png) 0 0 no-repeat;
				vertical-align: -3px;
			}
		}
	}

	.block-related-info-body {
		font-size: .875rem;
		line-height: 2;
	}
}


.block-related-link {
	display: flex;
	margin: 30px 0 0;
	padding: 30px 0 0;
	border-top: 1px dotted #999;

	.image {
		width: 120px;

		img {
			display: block;
		}
	}

	.link {
		flex: 1;
		display: flex;
		margin: 0 0 0 20px;
		align-items: center;

		.link-list-strong {
			margin-top: 0;

			> li:first-child {
				margin-top: 0;
			}
		}
	}
}



.block-gallery {
	margin: 40px 0 0;
	padding: 0;

	.view {
		position: relative;

		@media (max-width: $breakpoint) {
			margin-left: -15px;
			margin-right: -15px;
		}
	}

	.view-body {
		position: relative;
		overflow: hidden;
	}

	.items {
		position: relative;
		left: 0;
		display: flex;
		margin: 0;
		padding: 0;
		width: 500%;
		list-style-type: none;
		transition: left .4s ease-in-out;
	}

	.item {
		img {
			display: block;
			margin: auto;
			max-width: 100%;
		}
	}

	.prev,
	.next {
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 36px;
		height: 36px;
		opacity: 0;
		transition: opacity .1s ease-in-out;

		@media (max-width: $breakpoint) {
			display: none;
		}
	}
	.prev {
		left: -18px;
		background: url(../img/icon_prev.png) 0 0 no-repeat;
	}
	.next {
		right: -18px;
		background: url(../img/icon_next.png) 0 0 no-repeat;
	}
	&:hover {
		.prev,
		.next {
			opacity: 1;
		}
	}

	.selector {
		display: flex;
		margin: 0 -10px;
		padding: 0;
		list-style-type: none;
		flex-wrap: wrap;

		@media (max-width: $breakpoint) {
			margin-left: -5px;
			margin-right: -5px;
		}

		> li {
			box-sizing: border-box;
			margin: 20px 0 0;
			padding: 0 10px;
			width: 20%;

			@media (max-width: $breakpoint) {
				margin-top: 15px;
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		a,
		img {
			display: block;
			margin: auto;
			max-width: 100%;
		}

		a {
			position: relative;

			&.selected {
				opacity: 1;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					border: 4px solid #c00;

					@media (max-width: $breakpoint) {
						border-width: 2px;
					}
				}
			}
		}
	}
}



.block-product {
	@extend %clearfix;

	> .section-header,
	> .section-body {
		margin-left: 280px;

		@media (max-width: $breakpoint) {
			margin-left: 0;
		}
	}

	.block-product-thumb {
		float: left;
		display: block;
		margin: auto;

		@media (max-width: $breakpoint) {
			float: none;
			margin-bottom: 30px;
		}
	}
}



.block-qa {
	margin: 40px 0 0;

	& + & {
		margin-top: 10px;
		border-top: 1px dotted #999;
	}

	.qa {
		@extend %clearfix;
		position: relative;
		margin: 10px 0 0;
		padding: 20px;
		background: #f2f2f2;
		border-radius: 6px;
		cursor: pointer;

		&:before {
			content: "";
			position: absolute;
			right: 32px;
			top: 32px;
			border-top: 8px solid #999;
			border-right: 6px solid transparent;
			border-left: 6px solid transparent;
			transition: transform .1s ease-in-out;
		}

		> dt,
		> dd {
			margin: 0;
			padding: 0;
		}

		> .title {
			position: relative;
			margin: 0 40px 0 0;
			padding: 0 0 0 40px;
			font-weight: 700;
			font-size: 1.25rem;
			line-height: 1.5;

			@media (max-width: $breakpoint) {
				margin-left: 0;
			}

			&:before {
				content: "Q";
				position: absolute;
				left: 0;
				top: -2px;
				font-size: 1.375rem;
				line-height: 1.5;
			}

			&:hover {
				opacity: .8;
				transition: opacity .2s ease-in-out;
			}
		}

		> .description {
			clear: both;
			margin:  0 -20px;
			font-size: .875rem;
			line-height: 2;
			overflow: hidden;
			height: 0;

			.accordion-content {
				padding: 10px 0 0;
			}
		}

		.qa-body {
			position: relative;
			padding: 0 20px 0 60px;

			&:before {
				content: "A";
				position: absolute;
				left: 23px;
				top: 0;
				color: #c00;
				font-weight: 700;
				font-size: 1.375rem;
				line-height: 1.5;
			}
		}

		.qa-footer {
			display: flex;
			margin: 20px 0 0;
			padding: 30px 20px 10px;
			border-top: 1px dotted #ccc;
			justify-content: center;
			align-items: center;

			@media (max-width: $breakpoint) {
				display: block;
			}

			p {
				margin: 0 20px 0 0;
				padding: 0;

				@media (max-width: $breakpoint) {
					margin: 0 0 20px;
				}
			}

			.button {
				background: #fff;
				color: $color_text;


				&:hover {
					.text {
						opacity: .7;
					}
				}

				&:after {
					display: none;
				}
			}
		}

		&.open {
			&:before {
				transform: rotate(180deg);
			}

			> .description {
				height: auto;
			}
		}
	}
}



.block-contact {
	margin: 0;
	padding: 20px 0;
	text-align: center;

	> dt,
	> dd {
		margin: 0;
		padding: 0;
	}

	.title {
		font-weight: 700;
		font-size: 1.25rem;
	}

	.tel {
		margin-top: 10px;
		font-weight: 700;
		font-size: 2rem;
		line-height: 1.25em;
	}

	.time {
		font-size: .875rem;
		line-height: 1.5;
	}

	.buttons {
		margin-top: 20px;
	}
}


