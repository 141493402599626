@charset 'utf-8';
@import '../_env.scss';

.list-normal {
	margin: 16px 0 0;
	padding: 0;
	list-style-type: none;
	font-size: .875rem;
	line-height: 2;

	> li {
		margin: 14px 0 0;
		padding: 0 0 0 16px;
		text-indent: -16px;

		&:first-child {
			margin-top: 0;
		}

		&:before {
			content: "";
			display: inline-block;
			margin: 0 12px 0 0;
			border-radius: 2px;
			width: 4px;
			height: 4px;
			background: #333;
			vertical-align: 4px;
		}

		> * {
			text-indent: 0;
		}
	}
}

.list-ordered {
	margin: 16px 0 0;
	padding: 0;
	border-bottom: 1px solid #ccc;
	list-style-type: none;
	line-height: 2;
	counter-reset: order;

	> li {
		margin: 0;
		padding: 12px 0 12px 2em;
		border-top: 1px solid #ccc;
		text-indent: -2em;
		counter-increment: order;

		> * {
			text-indent: 0;
		}

		&:before {
			content: counter(order) ".";
			display: inline-block;
			width: 2em;
			text-indent: 0;
		}
	}
}

.list-ordered-plain {
	margin: 30px 0 0;
	padding: 0;
	list-style-type: none;
	line-height: 2;
	counter-reset: order;

	> li {
		margin: 1em 0 0;
		padding: 0 0 0 2em;
		text-indent: -2em;
		counter-increment: order;

		> * {
			text-indent: 0;
		}

		&:before {
			content: counter(order) ".";
			display: inline-block;
			width: 2em;
			text-indent: 0;
		}
	}
}



.list-definition {
	margin: 10px 0 0;
	padding: 0;

	> dt {
		margin: 0;
		padding: 0;
		font-weight: 700;
		line-height: 1.5;
	}

	> dd {
		margin: 10px 0 0;
		padding: 0;
		font-size: .875rem;
		line-height: 2;

		.block-image:first-child {
			margin-top: 20px;
		}
	}
}
a {
	.list-definition {
		> dt {
			&:before {
				content: "";
				display: inline-block;
				margin: 0 5px 0 0;
				width: 18px;
				height: 18px;
				background: url(../img/icon_index.png) 0 0 no-repeat;
				vertical-align: -3px;
			}
		}
	}

	&:hover {
		.list-definition {
			opacity: .7;
		}
	}
}
.block-image + .list-definition {
	margin-top: 20px;
}



.list-definition-parallel {
	display: flex;
	margin: 30px 0 0;
	padding: 0;
	align-items: flex-start;
	font-size: 1.125rem;
	line-height: 1.5;

	@media (max-width: $breakpoint) {
		font-size: 1rem;
	}

	> dt {
		margin: 0;
		padding: 0;
		font-weight: 700;

		&:after {
			content: "：";
		}
	}

	> dd {
		margin: 0;
		padding: 0;

		small {
			color: inherit;
			font-size: .875rem;
		}
	}
}


.list-columns {
	display: flex;
	flex-wrap: wrap;
	margin: 16px 0 0;
	padding: 0;
	list-style-type: none;
	line-height: 2;

	@media (max-width: $breakpoint) {
		display: block;
	}

	> li {
		margin: 0;
		width: 50%;

		@media (max-width: $breakpoint) {
			width: auto;
		}
	}
}



.list-plain {
	margin: 16px 0 0;
	padding: 0;
	list-style-type: none;
	line-height: 2;

	> li {
		margin: 0;
	}
}



.list-banner {
	margin: 40px 0 0;
	padding: 0;
	list-style-type: none;

	> li {
		margin: 20px 0 0;
	}

	a,
	img {
		display: block;
		max-width: 100%;
	}
}



.list-step {
	margin: 30px 0 0;
	padding: 0;
	list-style-type: none;
	counter-reset: step;

	> li {
		position: relative;
		margin: 10px 0 0;
		padding: 20px 20px 20px 60px;
		border-radius: 6px;
		background: #f2f2f2;
		font-size: .875rem;
		line-height: 2;
		counter-increment: step;

		&:before {
			content: counter(step);
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			margin: auto;
			width: 60px;
			height: 2em;
			font-size: 1.25rem;
			font-weight: 700;
			text-align: center;
		}
	}
}



.list-checkbox {
	display: flex;
	margin: 10px 0 0;
	padding: 0;
	list-style-type: none;
	flex-wrap: wrap;

	> li {
		margin: 5px 0 0;
		width: 16.666666%;
	}
}



.list-manually {
	margin: 16px 0 0;
	padding: 0;
	list-style-type: none;
	font-size: .875rem;
	line-height: 2;

	> li {
		margin: 14px 0 0;
		padding: 0 0 0 2.75em;
		text-indent: -2.75em;

		&:first-child {
			margin-top: 0;
		}

		> * {
			text-indent: 0;
		}
	}
}

