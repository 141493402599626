@charset 'utf-8';
@import '../_env.scss';

.pc {
	@media (max-width: $breakpoint) {
		display: none;
	}
}

.sp {
	@media (min-width: $breakpoint) {
		display: none;
	}
}

.note {
	margin: 0 0 -14px;
	padding: 0;
	font-size: 75%;
	text-align: right;
}

.right {
	text-align: right;
}

.center {
	text-align: center;
}



.news-category {
	margin: 0 0 10px;
	padding: 8px 0;
	display: inline-block;
	width: 150px;
	background: #e5e5e5;
	text-indent: 0;
	text-align: center;
	font-size: 0.75rem;
	font-weight: 700;
}
.table-news {
	.news-category {
		margin-left: 16px;

		@media (max-width: $breakpoint) {
			margin-left: 0;
		}
	}
}

.page {
	> .header {
		.news-category {
			display: block;
			margin: 10px 0 0 auto;
			line-height: 1.5;
		}
	}
}
.small {
	margin: 1.25em 0 0;
	font-size: .875rem;
	line-height: 2;
}

.checkbox {
	cursor: pointer;

	input {
		display: none;
	}

	.checkbox-text {
		&:before {
			content: "";
			display: inline-block;
			margin: 0 5px 0 0;
			width: 22px;
			height: 22px;
			border: 1px solid #c5c5c5;
			border-right-color: #dbdbdb;
			border-bottom-color: #dbdbdb;
			border-radius: 2px;
			box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.05);
			background: $color_bg;
			vertical-align: middle;
		}
	}

	:checked ~ .checkbox-text {
		&:before {
			background: $color_bg url(../img/icon_check.png) 50% 50% no-repeat;
			background-size: 16px 12px;
		}
	}
}



.animate-fade-in {
	visibility: hidden;

	&.animate {
		visibility: visible;
		animation: animate-fade-in .8s ease-out;
	}
}
@keyframes animate-fade-in {
	0% {
		opacity: 0;
		transform: translateY(40px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}



.hr {
	margin: 35px 0 0;
	border-top: 1px solid #ccc;

	hr {
		display: none;
	}
}


